<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title-large>  {{ headerTitle }} </f7-nav-title-large>
    </f7-navbar>
    <!-- Page content-->
    <form
    novalidate
    @submit="submit"
    >
    <f7-block strong :innerHTML="headerDescription">
      
      <div >ลูกค้าแจ้งการถอนเงินโดยการระบุข้อมูลตามด้านล่างให้ถูกต้อง</div>
    </f7-block> 
 
    <f7-block-title>แบบฟอร์มถอนเงิน</f7-block-title>

<f7-card >
    
    <f7-card-content>
      <f7-list>
        <f7-list-input
          label="User ของคุณ"
          type="text"
          v-model:value="form.c_username"
          placeholder="บัญชีที่คุณต้องการดำเนินการ หรือ user ของคุณ"
        ></f7-list-input>
        
        <f7-list-input
          label="จำนวนเงินถอน"
          type="number"
          v-model:value="form.transferAmount"
          placeholder="ตัวเลขและทศนิยม"
          @change="ParseInteger"
        ></f7-list-input>

        <!-- <f7-list-input
          label="ธนาคาร"
          type="select"
          v-model:value="form.bank"
          >
          <option  
          v-for="item in bank" 
          :key="item.text" 
          :value="item.value"> 
            {{item.text}}
          </option>
        </f7-list-input>
        
        <f7-list-input
          label="ชื่อ-นามสกุลบัญชีลูกค้า"
          type="text"
          v-model:value="form.customerName"
          placeholder="ตัวอักษร"
        ></f7-list-input> -->

        <f7-list-input
          label="เลขบัญชีธนาคารของคุณ"
          type="number"
          v-model:value="form.c_accountno"
          
          placeholder="ใส่เฉพาะตัวเลข ไม่ต้องใส่ขีด"
        ></f7-list-input>

      

        <!-- <f7-list-input
          label="เบอร์โทรศัพท์"
          type="number"
          v-model:value="form.c_mobile"
          placeholder="ใส่เฉพาะตัวเลข ไม่ต้องใส่ขีด"
        ></f7-list-input> -->
 
        <f7-list-input
          label="รหัสลับ "
          type="password"
          v-model:value="form.c_passmove"
          placeholder="*****"
        ></f7-list-input>
        
        
      </f7-list>
    </f7-card-content>
    
  </f7-card>
 

    <f7-block-title>ตรวจสอบข้อมูลของคุณก่อนทำการยืนยันข้อมูล</f7-block-title>
    <f7-block strong>
        <div v-if="errorMsg && errorMsg !== ''"> {{ errorMsg }} </div>
        <f7-button :disabled="isSubmit" preloader :loading="isSubmit" type="submit" class="col" large fill raised color="green">ยืนยันข้อมูล</f7-button>
      
    </f7-block>
    </form>

  </f7-page>
</template>
<script>
import { ref, onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import bank from '../config/bank.json';
import ownAccount from '../config/own.json';
import store from '../js/store';
import ApiService from "../utils/api.service";
import settings from '../config/settings';

export default {
  props: {
    f7router: Object,
  },
  data(){
    return {
      bank: bank,
      ownAccount: ownAccount,
      form: {
        //  c_username: "ufftcs4d139",
        // transferAmount: 300,
        // c_accountno: "6534108457",
        // c_mobile: "0898189714",
        // c_passmove: "2530",
      },
      companyName: "",
      headerTitle: "",
      headerDescription: "",
      formTitle: "",
      imageData: null,
      isSubmit: false,
      linkCheckHistoryTransfer: "",
      messageResponseEnd: "",
      errorMsg: "",
    }
  },
  setup() {
    
     
      onMounted(() => {
        f7ready(() => {
          f7.dialog.preloader();
        });
      });

  },
  mounted(){
            ApiService.get("with-draw-form-template").then(res => {
            f7.dialog.close();
            this.companyName = res.data.companyName ? res.data.companyName : "";
            this.headerTitle = res.data.headerTitle ? res.data.headerTitle : "";
            this.headerDescription = res.data.headerDescription ? res.data.headerDescription : "";
            this.formTitle = res.data.formTitle ? res.data.formTitle : "";
            this.linkCheckHistoryTransfer = res.data.linkCheckHistoryTransfer ? res.data.linkCheckHistoryTransfer : "";
            this.messageResponseEnd = res.data.messageResponseEnd ? res.data.messageResponseEnd : "";
             });

            if(settings.useLineLiff){
              window.liff.ready.then(() => {
              // do something you want when liff.init finishes
                window.liff.getProfile().then((res) => {

                  // this.objectTest = JSON.stringify(res)
                  this.form.lineId = res && res.userId ? res.userId : null;
                  this.form.lineData = res;
                  this.form.lineData.email  = liff.getDecodedIDToken().email;
                  store.dispatch('setLine', res)
                })
              })
            }
             

  },
  methods:{
    ParseInteger(e){
      // console.log(e.target.value)
      if(parseInt(e.target.value) < 200){
        f7.dialog.alert("ขั้นต่ำการถอนเงิน 200 บาทขึ้นไปค่ะ")
        this.form.transferAmount = "200"
      }else{
        this.form.transferAmount = parseInt(e.target.value)
      }
      
      
    },
    currentDateTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      return now.toISOString().slice(0,16);
    },
    currentDateLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString().slice(0,10))
      return now.toISOString().slice(0,10);
    },
    currentTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString(), now.toISOString().slice(11,16))
      return now.toISOString().slice(11,16);
    },
    
    submitDev(e){
      e.preventDefault();
      // console.log(this.transferDate, this.transferTime, new Date(`${this.transferDate} ${this.transferTime}`));
     
     if(!this.transferDate || !this.transferTime){
        f7.dialog.alert("กรุณาระบุวัน/เวลาโอนเงิน")
      }else{
        this.form.transferDateTime = new Date(parseInt(this.transferDate.split('-')[0]), parseInt(this.transferDate.split('-')[1])-1 ,parseInt(this.transferDate.split('-')[2]), parseInt(this.transferTime.split(":")[0]), parseInt(this.transferTime.split(":")[1]), 0, 0)
      }

      console.log(this.transferDate)
      // Development
    //    ApiService.post("transfers", this.form).then(r => {
              
    //           if(r.status === 200){
    //             f7.dialog.alert("บันทึกข้อมูลสำเร็จ กรุณารอระบบตรวจสอบสักครู่นะคะ") 
    //             if(settings.useLineLiff){
    //               setTimeout(function(){ window.liff.closeWindow(); }, 3000)
    //             }
    //           }else{
    //             f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
    //             this.isSubmit = false;
    //           }
    //         });
    },
    submit(e){
      e.preventDefault();
      // console.log(this.form);

      this.isSubmit = true;

      if(!this.form.c_username){
        f7.dialog.alert("กรุณาระบุ Username ของคุณให้ถูกต้อง")
      }

      if(!this.form.transferAmount){
        f7.dialog.alert("กรุณาระบุ ถอนเงิน ของคุณให้ถูกต้อง")
      }

      // if(!this.form.bank){
      //   f7.dialog.alert("กรุณาระบุ ธนาคาร ของคุณ")
      // }

      // if(!this.form.customerName){
      //   f7.dialog.alert("กรุณาระบุ ชื่อ-นามสกุล ของคุณ")
      // }

      if(!this.form.c_accountno){
        f7.dialog.alert("กรุณาระบุ เลขบัญชีธนาคาร ของคุณให้ถูกต้อง")
      }

      // if(!this.form.c_mobile){
      //   f7.dialog.alert("กรุณาระบุ เบอร์ติดต่อ ของคุณให้ถูกต้อง")
      // }

      if(!this.form.c_passmove){
        f7.dialog.alert("กรุณาระบุ รหัสลับ ของคุณให้ถูกต้อง")
      }
       
  

      // f7.dialog.alert( parseInt(this.transferDate.split('-')[1])-1)

      if(this.form.c_username 
      && this.form.transferAmount 
      && this.form.c_accountno 
      // && this.form.c_mobile 
      && this.form.c_passmove ){
        
        ApiService.get("send-data-log-with-draws", `count?created_at_gte=${new Date().toISOString().split('T')[0]}T00:00:00.000Z&c_username_containss=${this.form.c_username}`).then(res => {
          // console.log(res)
          if(res.data < 4){
                ApiService.put("check-users-withdraw", this.form).then(r => {
                  if(r.status === 200){

                      if(r.data.length === 1){
                        this.isSubmit = false;

                        this.f7router.navigate('/confirm', {
                          props: {
                              c_username: r.data[0].c_username ? r.data[0].c_username : this.form.c_username,
                              transferAmount: this.form.transferAmount,
                              c_accountno: r.data[0].c_accountno ? r.data[0].c_accountno : this.form.c_accountno,
                              // c_mobile: r.data[0].c_mobile ? r.data[0].c_mobile : this.form.c_mobile,
                              c_passmove: r.data[0].c_passmove ? r.data[0].c_passmove : this.form.c_passmove,
                              customerName: r.data[0].c_accountName ? r.data[0].c_accountName : '',
                            }
                          })
                      }else{
                        f7.dialog.alert("ระบบตรวจสอบข้อมูลของคุณแล้ว ไม่ถูกต้องค่ะ")
                        this.errorMsg = "ระบบตรวจสอบข้อมูลของคุณแล้ว ไม่ถูกต้องค่ะ กรุณาลองใหม่อีกครั้ง"
                        this.isSubmit = false;
                      }
                    // f7.dialog.alert("บันทึกข้อมูลสำเร็จ กรุณารอระบบตรวจสอบสักครู่นะคะ") 
                    // if(settings.useLineLiff){
                    //     if(this.messageResponseEnd !== ""){
                    //       liff.sendMessages([
                    //         {
                    //           type: 'text',
                    //           text: this.messageResponseEnd
                    //         }
                    //       ]).then(() => {
                    //           console.log('message sent');
                    //         })
                    //         .catch((err) => {
                    //           console.log('error', err);
                    //         });
                    //     }
                    //     if(this.linkCheckHistoryTransfer !== ""){
                    //       liff.sendMessages([
                    //         {
                    //           type: 'text',
                    //           text: this.linkCheckHistoryTransfer
                    //         }
                    //       ]).then(() => {
                    //           console.log('message sent');
                    //         })
                    //         .catch((err) => {
                    //           console.log('error', err);
                    //         });
                    //     }
                      

                    //   setTimeout(function(){ window.liff.closeWindow(); }, 3000)
                    // }
                  }else{
                    f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
                    this.errorMsg = JSON.stringify(this.form)
                    this.isSubmit = false;
                  }
              });
          }else{
            f7.dialog.alert("คุณดำเนินการถอนเกินกว่าที่กำหนดไว้ กรุณาลองใหม่อีกครั้งในวันถัดไปค่ะ")
          }

        })

        
      
      }else{
        this.isSubmit = false;
      }
      

    },
    onFileChange(event) {
      
        // Reference to the DOM input element
        var input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            var reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                this.imageData = e.target.result;
            }
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(input.files[0]);
            this.form.fileUpload = input.files
        }
    }
  }
}
</script>
<style scoped>
.dateTimeInput {
    max-width: 150vw !important; 
}
</style>