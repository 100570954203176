<template>
  <f7-page name="underconstruction">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title-large>  ปิดปรับปรุงระบบประจำวัน </f7-nav-title-large>
    </f7-navbar>
    <!-- Page content-->
    
    <f7-block stron>
      
      <div >ระบบปิดปรับปรุง กรุณาเข้าใช้ใหม่ภายหลัง </div>
      <div >เปิดทำการ 01.00 - 00.30 (+1) น. ทุกวัน</div>
    </f7-block> 

  </f7-page>
</template>
<script>
import { ref, onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import settings from '../config/settings';

export default {
  props: {
    f7router: Object,
  },
  data(){
    return {
    }
  },
}
</script>