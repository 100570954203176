
import HomePage from '../pages/home.vue';
import UnderConstructionPage from '../pages/underconstruction.vue';
import ConfirmPage from '../pages/confirm.vue';
import NotFoundPage from '../pages/404.vue';

const inTime = () => {
  let start =  (0 * 60) + 0; // 01.30 
  let end   = (1 * 60) + 30; // 00.30
  const now = new Date();
  let time = (now.getHours() * 60) + now.getMinutes();
  return time >= start && time < end;
}
var routes = [
  {
    path: '/',
    component: inTime() ? UnderConstructionPage : HomePage,
  },
  {
    path: '/confirm',
    component: ConfirmPage,
    options: {
      props: {
        customerName: 'bar',
      },
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
