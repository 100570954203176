<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar>
      <f7-nav-title-large>  ยืนยันการถอนเงิน </f7-nav-title-large>
    </f7-navbar>
    <!-- Page content-->
    <form
    novalidate
    @submit="submit"
    >
    <f7-block strong >
      
      <div >การถอนเงินครั้งนี้เป็นการแจ้งให้ระบบทราบว่า ท่านกำลังจะดำเนินการถอนเงินดังกล่าว <br/> อาจใช้ระยะเวลาการดำเนินการต่อภายใน 15 นาทีถัดจากนี้ไป <br/> <br/> หากเกินระยะเวลาที่กำหนด ท่านยังไม่ได้รับเงิน กรุณาติดต่อทางเจ้าหน้าที่</div>
    </f7-block> 
 
<f7-card >
    
    <f7-card-content>
      <f7-list>
        <f7-list-input
          label="User ของคุณ"
          type="text"
          v-model:value="c_username"
          placeholder="บัญชีที่คุณต้องการดำเนินการ หรือ user ของคุณ"
          readonly
        ></f7-list-input>
        
        <f7-list-input
          label="จำนวนเงินถอน"
          type="number"
          v-model:value="transferAmount"
          placeholder="ตัวเลขและทศนิยม"
          readonly
        ></f7-list-input>

      
        
        <f7-list-input
          label="ชื่อ-นามสกุลบัญชีลูกค้า"
          type="text"
          v-model:value="customerName"
          placeholder="ตัวอักษร"
          readonly
        ></f7-list-input> 

        <f7-list-input
          label="เลขบัญชีธนาคารของคุณ"
          type="text"
          v-model:value="c_accountno"
          readonly
          placeholder="ใส่เฉพาะตัวเลข ไม่ต้องใส่ขีด"
        ></f7-list-input>

      

        <!-- <f7-list-input
          label="เบอร์โทรศัพท์"
          type="text"
          v-model:value="c_mobile"
          placeholder="ใส่เฉพาะตัวเลข ไม่ต้องใส่ขีด"
          readonly
        ></f7-list-input> -->
 
        <f7-list-input
          label="รหัสลับ "
          type="password"
          v-model:value="c_passmove"
          placeholder="*****"
          readonly
        ></f7-list-input>
        
        
      </f7-list>
    </f7-card-content>
    
  </f7-card>
 

    <f7-block-title>ตรวจสอบข้อมูลของคุณก่อนทำการยืนยันข้อมูล</f7-block-title>
    <f7-block strong>
        <div v-if="errorMsg && errorMsg !== ''"> {{ errorMsg }} </div>
        <f7-button :disabled="isSubmit" preloader :loading="isSubmit" type="submit" class="col" large fill raised color="green">ยืนยันข้อมูลอีกครั้ง</f7-button>
        <br/>
        <f7-button class="col" large fill raised color="red" @click="f7router.back()">ยกเลิก</f7-button>

    </f7-block>
    </form>

  </f7-page>
</template>
<script>
import { ref, onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import bank from '../config/bank.json';
import ownAccount from '../config/own.json';
import store from '../js/store';
import ApiService from "../utils/api.service";
import settings from '../config/settings';

export default {
  props: {
    c_username: String,
    transferAmount: Number,
    c_accountno: String,
    // c_mobile: String,
    c_passmove: String,
    customerName: String,
    f7router: Object,
  },
  data(){
    return {
      c_username: this.c_username,
      transferAmount: this.transferAmount,
      c_accountno: this.c_accountno,
      // c_mobile: this.c_mobile,
      c_passmove: this.c_passmove,
      customerName: this.customerName,
     
      isSubmit: false,
      linkCheckHistoryTransfer: "",
      messageResponseEnd: "",
      errorMsg: "",
    }
  },
  setup() {
    
     
      onMounted(() => {
        f7ready(() => {
          // f7.dialog.preloader();
        });
      });

  },
  mounted(){
            ApiService.get("with-draw-form-template").then(res => {
              this.companyName = res.data.companyName ? res.data.companyName : "";
              this.headerTitle = res.data.headerTitle ? res.data.headerTitle : "";
              this.headerDescription = res.data.headerDescription ? res.data.headerDescription : "";
              this.formTitle = res.data.formTitle ? res.data.formTitle : "";
              this.linkCheckHistoryTransfer = res.data.linkCheckHistoryTransfer ? res.data.linkCheckHistoryTransfer : "";
              this.messageResponseEnd = res.data.messageResponseEnd ? res.data.messageResponseEnd : "";
             });

            if(settings.useLineLiff){
              window.liff.ready.then(() => {
              // do something you want when liff.init finishes
                window.liff.getProfile().then((res) => {

                  // this.objectTest = JSON.stringify(res)
                  this.form.lineId = res && res.userId ? res.userId : null;
                  this.form.lineData = res;
                  this.form.lineData.email  = liff.getDecodedIDToken().email;
                  store.dispatch('setLine', res)
                })
              })
            }
             

  },
  methods:{
    currentDateTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      return now.toISOString().slice(0,16);
    },
    currentDateLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString().slice(0,10))
      return now.toISOString().slice(0,10);
    },
    currentTimeLocalInput() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      // console.log(now.toISOString(), now.toISOString().slice(11,16))
      return now.toISOString().slice(11,16);
    },
    
    submitDev(e){
      e.preventDefault();
      // console.log(this.transferDate, this.transferTime, new Date(`${this.transferDate} ${this.transferTime}`));
     
     if(!this.transferDate || !this.transferTime){
        f7.dialog.alert("กรุณาระบุวัน/เวลาโอนเงิน")
      }else{
        this.form.transferDateTime = new Date(parseInt(this.transferDate.split('-')[0]), parseInt(this.transferDate.split('-')[1])-1 ,parseInt(this.transferDate.split('-')[2]), parseInt(this.transferTime.split(":")[0]), parseInt(this.transferTime.split(":")[1]), 0, 0)
      }

      console.log(this.transferDate)
      // Development
    //    ApiService.post("transfers", this.form).then(r => {
              
    //           if(r.status === 200){
    //             f7.dialog.alert("บันทึกข้อมูลสำเร็จ กรุณารอระบบตรวจสอบสักครู่นะคะ") 
    //             if(settings.useLineLiff){
    //               setTimeout(function(){ window.liff.closeWindow(); }, 3000)
    //             }
    //           }else{
    //             f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
    //             this.isSubmit = false;
    //           }
    //         });
    },
    submit(e){
      e.preventDefault();
     
      this.isSubmit = true;

    
  
     
        ApiService.put("run-procedure-with-draw", {
          c_username: this.c_username,
          transferAmount: this.transferAmount,
          c_accountno: this.c_accountno,
          // c_mobile: this.c_mobile,
          c_passmove: this.c_passmove,
          customerName: this.customerName,
        }).then(r => {
              if(r.status === 200 && r.data === true){
                f7.dialog.alert("บันทึกข้อมูลสำเร็จ ระบบได้รับข้อมูลถูกต้องแล้วค่ะ") 
                if(settings.useLineLiff){
                    if(this.messageResponseEnd !== ""){
                      liff.sendMessages([
                        {
                          type: 'text',
                          text: this.messageResponseEnd
                        }
                      ]).then(() => {
                          console.log('message sent');
                        })
                        .catch((err) => {
                          console.log('error', err);
                        });
                    }
                    if(this.linkCheckHistoryTransfer !== ""){
                      liff.sendMessages([
                        {
                          type: 'text',
                          text: this.linkCheckHistoryTransfer
                        }
                      ]).then(() => {
                          console.log('message sent');
                        })
                        .catch((err) => {
                          console.log('error', err);
                        });
                    }
                  

                  setTimeout(function(){ window.liff.closeWindow(); }, 3000)
                }
              }else{
                f7.dialog.alert("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง")
                this.errorMsg = "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง"
                this.isSubmit = false;
              }
          });
   
      

    },
    onFileChange(event) {
      
        // Reference to the DOM input element
        var input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            var reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
                // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                // Read image as base64 and set to imageData
                this.imageData = e.target.result;
            }
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(input.files[0]);
            this.form.fileUpload = input.files
        }
    }
  }
}
</script>
<style scoped>
.dateTimeInput {
    max-width: 150vw !important; 
}
</style>